import React, { Component } from "react";
import { FaSearch } from "react-icons/fa";

class TableContents extends Component {
    constructor(props){
        super(props);
        this.state={
            data:this.props.data,
        }
    }
    render() {
        const {data} = this.state;

        let tableItems = []

        if(data.length!=0  || data!=null || data!=undefined){
          data.forEach(e=>{
            tableItems.push(
              <tr>
                <td>{e.first_name} {e.last_name}</td>
                <td>{e.email}</td>
                <td>{e.mobile_number}</td>
                {/* <td>Active<FaCircle style={{color:'green',marginLeft:'5px'}}/></td> */}
              </tr>
            )
          })
        }
        return (
            <>
                <div className="admin_search_bar mt-5">

                    <div class="input-group mb-3 w-50">
                        <input type="text" class="form-control" placeholder="Search here..." aria-label="Recipient's username" aria-describedby="basic-addon2" />
                        <span class="input-group-text" id="basic-addon2"><FaSearch /></span>
                    </div>
                </div>
                <div className="admins_table">
                    <table class="table table-responsive">
                        <thead>
                            <tr>
                                <th scope="col">Admin Name</th>
                                <th scope="col">Email Id</th>
                                <th scope="col">Phone Number</th>
                                {/* <th scope="col">Status</th> */}
                            </tr>
                        </thead>
                        <tbody>
                            {tableItems}

                        </tbody>
                    </table>
                </div>
            </>
        )
    }
}

export default TableContents