import React, { Component } from "react";

class PageNOtFound extends Component{
    render(){
        return(
            <>
            <div className="container text-center">
            <img className="text-center image_page_not" src="/images/pageNotFound.png" alt="page not found"/>
            <h1 className="titles">404</h1>
            <h4>Page Not Found!</h4>
            </div>
            </>
        )
    }
}

export default PageNOtFound