import {React,useEffect,useState} from "react";
import NavBar from "./components/NavBar/NavBar";
import { BrowserRouter, Route,Routes } from "react-router-dom";
import HeroSection from "./components/HeroSection/HeroSection";
import Login from "./components/Login/Login";
import Logout from "./components/Login/logout";
import Register from "./components/Register/Register";
import AddAdmin from "./components/SuperAdmin/AddAdmin";
import UserDashBoard from "./components/UserDashBoard/UserDashBoard";
import AdminDashBoard from "./components/AdminDashBoard/AdminDashBoard";
import DelieveryDash from "./components/DelievryDashBoard/DelieveryDash";
import SupAdminDash from "./components/SuperAdminDashBoard/SupAdminDash";
import CategoryWiseMenu from "./components/CategoryWiseMenu/CategoryWiseMenu";
import { jwtDecode } from "jwt-decode";
import AllSubScriptions from "./components/allSubscriptions/AllSubscriptions";
import PageNOtFound from "./components/pageNotFound/pageNotFound";
import Addmenu from "./components/SuperAdmin/AddMenus";


const App = () => {



  const [token, setToken] = useState("");
  const [role, setRole] = useState("");
  const [user_id, setUID] = useState(0);


  
  const handleLogin = (newToken, newuid, user_type) => {
    // window.open('/','_self')
    setToken(newToken);
    window.localStorage.setItem("user_type", user_type);
    window.localStorage.setItem("uid", newuid);

    console.log("User type ye hai: ", user_type);
    console.log("UID ye hai: ", newuid);
  };

  useEffect(() => {
    const storedToken = window.localStorage.getItem("token");
    if (storedToken) {
      setToken(storedToken);
      const storedRole = window.localStorage.getItem("user_type");
      const uid = window.localStorage.getItem('uid');
      if (storedRole) {
        setRole(storedRole);
        setUID(uid);
      }
      // if(fac_id) { setFaculty_ID(fac_id)}
    }

    if(localStorage.getItem("token")!=undefined || localStorage.getItem("token")!=null){
      console.log(token)
      let decodedToken = jwtDecode(localStorage.getItem("token"));
      console.log("Decoded Token", decodedToken);
      let currentDate = new Date();

      // JWT exp is in seconds
      if (decodedToken.exp * 1000 < currentDate.getTime()) {
        localStorage.clear();
        alert("Session Expired!")
      }
    }
    
  }, [token, role]);

  return (
    <>


   <BrowserRouter>
      <div>
        <NavBar></NavBar>
        <Routes>
          <Route path="/" exact element={<HeroSection/>} /> 
          
          {
            localStorage.getItem('token')!=undefined |  localStorage.getItem('token')!=null
            ?<>
            {
              localStorage.getItem('role')=='3' | localStorage.getItem('role')==3 ?
              <>
                <Route path="/Dashboard" element={<UserDashBoard/>} />
              </>
              :
              localStorage.getItem('role')=='2' | localStorage.getItem('role')==2 ?
              <>
                <Route path="/Dashboard" element={<AdminDashBoard/>} />
                <Route path="/AddMenu" element={<Addmenu/>} />
              </>
              :
              localStorage.getItem('role')=='1' | localStorage.getItem('role')==1 ?
              <>
                <Route path="/AddAdmins" element={<AddAdmin/>} />
                <Route path="/Dashboard" element={<SupAdminDash/>} />
                <Route path="/AddMenu" element={<Addmenu/>} />
                <Route path="/AllSubScriptions" element={<AllSubScriptions/>} />
              </>
              :
              <>
              </>
            }
              <Route path="/subCategories" element={<CategoryWiseMenu/>} />
              <Route path="/logout" element={<Logout/>} />
            
            </>:
            <>
              <Route path="/" exact element={<HeroSection/>} />
              <Route path="/login" element={<Login handleUserLogin={handleLogin}/>} />
              <Route path="/register" element={<Register/>} />
            </>
          }

        <Route path="/*" element={<PageNOtFound/>} />  

          {/* <Route path="/about-us" component={AboutUsPage} /> */}
        </Routes>
      </div>
    </BrowserRouter>


   {/*  */}

   {/* <DelieveryDash/> */}
   {/* <SupAdminDash/> */}
   
    </>
  

  );
};

export default App;