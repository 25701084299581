import React from 'react';
import './hero.css'
import { FaPlay } from 'react-icons/fa';
import { BiFoodMenu, BiStoreAlt, BiMoney } from 'react-icons/bi';
import cook from '../Images/cook2.svg'
import plane from '../Images/plane.svg'
import coin from '../Images/coin.svg'
import vector from '../Images/Vector.svg'
import Review from '../Reviews/Review';
import FAQ from '../FAQ/FAQ';
import Footer from '../Footer/Footer';
import SelectHome from '../selectHome/selectHome';
import Navbar from '../NavBar/NavBar';


const HeroSection = () => {
    return (
        <>
            <div className="container">

                <div className="main_hero">
                    <div className="left">
                        <div className="content">
                            <h1>Welcome to Test of India</h1>
                            <h1>where every bite brings</h1>
                            <h1>you closer to <span id="home">home</span></h1>
                        </div>
                        <div>


                            <button className='btn btn-warning btn-lg mt-5' style={{ backgroundColor: '#FF9F00', color: 'white', fontWeight: '700', fontSize: '23px' }}>Explore Our Menu   <FaPlay style={{ width: '0.8rem' }} color='white' /></button>
                        </div>
                    </div>
                    <div className="right">

                        <img src={vector} id="sw-js-blob-svg" />
                        {/* <svg id="sw-js-blob-svg" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg"
                    >
                        <defs>
                            <linearGradient id="sw-gradient" x1="0" x2="1" y1="1" y2="0">
                                <stop id="stop1" stopColor="rgba(248, 117, 55, 1)" offset="0%" />
                                <stop id="stop2" stopColor="rgba(251, 168, 31, 1)" offset="100%" />
                            </linearGradient>
                        </defs>
                        <path
                            fill="url(#sw-gradient)"
                            d="M19.6,-31.5C23.5,-28,23.6,-19.4,23.3,-12.8C23,-6.1,22.4,-1.5,23.4,4.5C24.4,10.6,26.9,18,24.8,22.9C22.7,27.7,16.1,29.9,8.9,33.4C1.7,37,-6,41.8,-13.6,41.7C-21.2,41.5,-28.7,36.4,-29.2,29.1C-29.7,21.7,-23.3,12.1,-23.2,4.2C-23,-3.7,-29,-9.9,-28.9,-14.4C-28.7,-19,-22.4,-22,-16.5,-24.6C-10.7,-27.3,-5.3,-29.6,1.2,-31.6C7.8,-33.5,15.7,-35,19.6,-31.5Z"
                            width="100%"
                            height="100%"
                            transform="translate(50 50)"
                            strokeWidth="0"
                            style={{ transition: "all 0.3s ease 0s;" }}
                        />
                    </svg> */}

                    </div>
                </div>

                <hr/>

                <div className="why_us mt-5" id='whyus'>
                    <div className="why_us_head">
                        <h2>Why Us ?</h2>
                        <div className="why_us_p">
                            <p>At Taste of Home, we take pride in delivering more than just meals. We offer a taste of comfort, a touch of tradition,
                                <br />
                                and a <span style={{
                                    color: 'orange'
                                }}><b>slice of home </b></span>in every lunch box.</p>
                        </div>
                    </div>

                    <div className="why_us_cards mt-5">
                        <div class="card" id="first_card">
                            {/* <BiFoodMenu class="card-img-top mt-5" alt="..." style={{height:'10rem'}} color='rgb(255, 148, 47)' /> */}
                            <img src={cook} class="card-img-top text-center" style={{ height: '10rem', width: '100%' }} />
                            <div class="card-body">
                                <h5 class="card-title text-center mt-0" style={{ fontSize: '20px' }}>Homemade Goodness</h5>
                            </div>
                        </div>

                        <div class="card" id="first_card">
                            <img src={plane} class="card-img-top text-center" style={{ height: '10rem', width: '100%' }} />
                            <div class="card-body">
                                <h5 class="card-title text-center mt-0">Convinence</h5>
                            </div>
                        </div>

                        <div class="card" id="first_card">
                            <img src={coin} class="card-img-top text-center" style={{ height: '10rem', width: '100%' }} />
                            <div class="card-body">
                                <h5 class="card-title text-center mt-0">Affordable</h5>
                            </div>
                        </div>
                    </div>
                </div>

                <hr/>


                <SelectHome/>

                <hr/>


                <div className="review" id='reviews'>
                    <Review />
                </div>

                <hr/>


                <div className="faq" id='faq'>
                    <FAQ />
                </div>

            </div>
            <div className="footer" id='concat'>
                <Footer />
            </div>
        </>
    );
};

export default HeroSection;
