import React, { Component } from "react";

class Logout extends Component{
    constructor(props){
        super(props);
        this.state={

        }
    }

    componentDidMount() {
        localStorage.clear();
        alert("Logged Out!");
        window.open('/','_self')
      }

    render(){
        
        return(
            <>
            </>
        )
    }
}

export default Logout;