import React, { Component, useEffect } from 'react'
import { FaSearch } from 'react-icons/fa';
import { FaCircle } from 'react-icons/fa';
import './supaddash.css'
import axios from 'axios';
import TableContents from './dashComponets';



class SupAdminDash extends Component {

  constructor(props) {
    super(props);
    this.state = {
      data: [],
      loading: true,
      error: null,
      url :process.env.REACT_APP_REACT_VIEW_DOC_API,
      userData:{},
      allUserData:[],
      dataToSend:[],
      searchPara:[],
      fiilter_data:[]
    };
    // this.knowMoreClickHandeler = this.knowMoreClickHandeler.bind(this);
  }

  componentDidMount() {
    this.fetchData();
  }

 fetchData = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/getAllAdmins`);
      this.setState({ data: response.data.data, dataToSend:response.data.data,fiilter_data:response.data.data, loading: false });
    } catch (error) {
      this.setState({ error: error, loading: false });
    }

    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/getUserInfo`,{
        headers: {
          'Content-Type': 'application/json',
          authorization: localStorage.getItem('token'),
        },
      });
      this.setState({ userData: response.data.data, loading: false });
    } catch (error) {
      this.setState({ error: error, loading: false });
    }

    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/getAllusers`,{
        headers: {
          'Content-Type': 'application/json',
          authorization: localStorage.getItem('token'),
        },
      });
      this.setState({ allUserData: response.data.data, loading: false });
    } catch (error) {
      this.setState({ error: error, loading: false });
    }
  };


handleAdmin=async(e)=>{
  const {data} = this.state;
  this.setState({dataToSend:data,fiilter_data:data});
}

hableUserSelect=async(e)=>{
  const {allUserData} = this.state;
  this.setState({dataToSend:allUserData,fiilter_data:allUserData});
}

handleSearch=(e)=>{
  const {dataToSend} = this.state;
  this.setState(
    {fiilter_data:dataToSend.filter(i=>(i.first_name.toLowerCase().includes(e.target.value.toLowerCase()) || i.last_name.toLowerCase().includes(e.target.value.toLowerCase())))}
  )
}
render(){
  const {data,userData,allUserData,dataToSend,fiilter_data} = this.state;

      let tableItems = []

      if(Array.isArray(fiilter_data)){
        fiilter_data.forEach(e=>{
          tableItems.push(
            <tr>
              <td>{e.first_name} {e.last_name}</td>
              <td>{e.email}</td>
              <td>{e.mobile_number}</td>
              {/* <td>Active<FaCircle style={{color:'green',marginLeft:'5px'}}/></td> */}
            </tr>
          )
        })
      }
  return (
    <div className='container'>
      <div className="profile">
        <div className="profile_info">
            <h2>Hello, {userData.first_name} {userData.last_name}</h2>
            <p>Welcome to Super Admin DashBoard</p>
            <div className="orders_sub_info">
                <div className="first">
                    <button className='btn know_more_btn' id='admin' onClick={this.handleAdmin}><b>Total Admins - </b><span>{data.length}</span></button>
                </div>
              
                <div className="first">
                    <button className='btn know_more_btn' id='user' onClick={this.hableUserSelect}><b> Users Our - </b><span>{allUserData.length}</span></button>
                </div>
            </div>
        </div>
      </div>

                <div className="admin_search_bar mt-5">

                    <div class="input-group mb-3 w-50">
                        <input type="text" onChange={this.handleSearch} class="form-control" placeholder="Search Name Here..." aria-label="Recipient's username" aria-describedby="basic-addon2" />
                    </div>
                </div>
                <div className="admins_table">
                    <table class="table table-responsive">
                        <thead>
                            <tr>
                                <th scope="col">Admin Name</th>
                                <th scope="col">Email Id</th>
                                <th scope="col">Phone Number</th>
                                {/* <th scope="col">Status</th> */}
                            </tr>
                        </thead>
                        <tbody>
                            {tableItems}
                        </tbody>
                    </table>
                </div>
    </div>
  )
}
}

export default SupAdminDash
