import axios from "axios";
import React from "react";
import { Component } from "react";
import Navbar from "../NavBar/NavBar";
import Footer from "../Footer/Footer";
import MenuCard from "./subComponents/menuCard";
import './catMenu.css'
import SubscribeUs from "./subComponents/subscribeUs";
class CategoryWiseMenu extends Component{
    constructor(props) {
        super(props);
        this.state = {
          vegData: null,
          nonvegData: null,
          loading: true,
          error: null,
          url :process.env.REACT_APP_REACT_VIEW_DOC_API,
          selectedMenu:{}
        };
        this.knowMoreClickHandeler = this.knowMoreClickHandeler.bind(this);
      }
    
      componentDidMount() {
        const params = new URLSearchParams(window.location.search);
        const query = params.get('id');

        if(localStorage.getItem('token')==undefined | localStorage.getItem('token')==null){
            alert("You need to login to visit this page!");
            window.open('/login','_self')
        }
        this.fetchData(query);
      }
    
      fetchData = async (q) => {
        try {
          const response = await axios.post(`${process.env.REACT_APP_API_URL}/getAllMenuItems`,{
            tag:'Veg',
            admin_id : q
          },{
            headers: {
              'Content-Type': 'application/json',
              authorization: localStorage.getItem('token'),
            },
          });
          console.log(response.data.data)
          this.setState({ vegData: response.data.data, loading: false });
        } catch (error) {
          this.setState({ error: error, loading: false });
        }


        try {
          const response = await axios.post(`${process.env.REACT_APP_API_URL}/getAllMenuItems`,{
            tag:'Non-Veg',
            admin_id : q
          },{
            headers: {
              'Content-Type': 'application/json',
              authorization: localStorage.getItem('token'),
            },
          });
          console.log(response.data.data)
          this.setState({ nonvegData: response.data.data, loading: false });
        } catch (error) {
          this.setState({ error: error, loading: false });
        }
      };

    knowMoreClickHandeler = async(menu_id,cat) =>{
      const {vegData,nonvegData} = this.state;
      console.log(menu_id,cat);
      if(cat=='Veg'){
        vegData.forEach(e => {
          if(e.menu_id==menu_id){
            this.setState({selectedMenu:e})
          }
        });
      }
      else if(cat=='Non-Veg'){
        nonvegData.forEach(e => {
          if(e.menu_id==menu_id){
            this.setState({selectedMenu:e})
          }
        });
      }
    }

    render(){
      const {vegData,nonvegData,url} = this.state;
      const vegMenuItens = [];
      const nonVegItems = [];

      if(vegData!=null){
        vegData.forEach(e => {
        let a = []
        
        String(e.discription).split('\n').forEach(i=>{
          a.push(
            <li>{i}</li>
          )
        })

          vegMenuItens.push(
            <MenuCard
              title={e.title}
              url = {`${url}/${e.banner_img}`}
              desc = {a}
              button_text="SubScribe Us"
              desc_title = "What We Serve"
              knowMoreClickHandeler = {this.knowMoreClickHandeler}
              cat="Veg"
              id  = {e.menu_id}
              toggle = "modal"
              target="#SubsribeUs"
          />
          )
        });
      }
      

      if(nonvegData!=null){
        nonvegData.forEach(e => {
        let a = []
        
        String(e.discription).split('\n').forEach(i=>{
          a.push(
            <li>{i}</li>
          )
        })

          nonVegItems.push(
            <MenuCard
              title={e.title}
              url = {`${url}/${e.banner_img}`}
              desc_title = "What We Serve"
              desc = {a}
              button_text="SubScribe Us"
              knowMoreClickHandeler = {this.knowMoreClickHandeler}
              cat="Non-Veg"
              toggle = "modal"
              target="#SubsribeUs"
              id  = {e.menu_id}
          />
          )
        });
      }
        return(
            <>
            <div className = 'container mt-3 mb-3'>
              <h2 className="text-center mt-3 titles">Veg</h2>
              <hr className="mt-0"/>
              <div className="card_container d-flex justify-content-around">
              {
                  vegMenuItens.length==0?
                  <div className="m-5">
                    <img  className="w-100" width={"300px"} src={`${url}/comming_soon.png`} alt="Coming Soon" />
                  </div>:vegMenuItens
                }
              </div>
             
            <hr className="mt-3"/>
            </div>

            {/* <hr /> */}
            <div className="container mt-3">
            <h2 className="text-center mt-3 titles">Non-Veg</h2>
            <hr className="mt-0"/>

            <div className="card_container d-flex justify-content-around">
                {
                  nonVegItems.length==0?
                  <div className="m-5">
                    <img  className="w-100" width={"300px"} src={`${url}/comming_soon.png`} alt="Coming Soon" />
                  </div>:nonVegItems
                }
              </div>
            </div>

            <SubscribeUs selectedMenu={this.state.selectedMenu}/>

            <Footer/>
            </>
        )
    }
}

export default CategoryWiseMenu;