import { React, useEffect, useState } from 'react'
import vector from '../Images/register_png.png'
import logo from '../Images/brand_logo.png'
import './addadmin.css'
import Register from '../Register/Register'
import axios from 'axios'


const AddAdmin = () => {

    const [banner,setBannerImg] = useState(null);

    const [formData, setFormData] = useState({
        first_name: '',
        last_name: '',
        email:'',
        mobile_number: '',
        address_type: 0,
        house_no: '',
        street: '',
        landmark: '',
        city: '',
        state: '',
        country: '',
        comp_name: '',
        zip_code: '',
        other_add: '',
        typeOfFood:'',
        category:'',
    });


    const [isSecondAddress, setSecondAddress] = useState(false);
    const [selectaddress, setSelectAddress] = useState(0);

    const secondaddress = () => {
        setSecondAddress(true);
    }

    const handleaddresstype = (address_type) => {
        if (address_type == 1) {
            setSelectAddress(1)
        }
        else if (address_type == 2) {
            setSelectAddress(2)
        }
        else if (address_type == 3) {
            setSelectAddress(3)
        }
        else {
            console.log('None')
        }
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value
        }));
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            formData['banner'] = banner;
            const res = await axios.post(`${process.env.REACT_APP_API_URL}/addAdmins`,formData,{
                headers:{
                    'Authorization': localStorage.getItem('token')
                }
            });
    
            if (res.status === 200) {
                console.log(res.data);
                alert('Admin Has Been Registered')
                window.open('/dashboard','_self')
            }
            console.log(res.error);
        } catch (error) {
            console.log("Error:", error);
        }
    };

    const handleFileSelect=async(e)=>{
        const { name, files } = e.target;
    
        const formData = new FormData();
        formData.append("file", files[0]); 
    
        // Make an AJAX request to upload the file
        axios.post(`${process.env.REACT_APP_API_URL}/uploadFile`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': localStorage.getItem('token')
            }
        })
        .then(response => {
            console.log(response.data.filename)
            setBannerImg(response.data.filename);
            alert('Document Uploaded');
        })
        .catch(error => {
            console.error(`Error uploading file:`, error);
        });
    }

    return (



        <div className="container">

            <div className="login_container mt-5">
                <div className="left_content">
                    <img src={vector} className='login_svg' />
                </div>

                <div className="right_content">
                    <div className="right_head">
                        <h2>Add Admin</h2>

                        <p>Add Admins by filling up this form with their information.</p>

                    </div>

                    <div className='row'>
                        <div className='col-lg-12 mb-3'>
                            <label className='know_more_btn w-100 text-center p-2 custom-file-upload' for="file-upload">
                                    Upload Banner Image
                                </label>
                            <input className='banner_img' name='banner_img' id="file-upload" type="file" onChange={(e)=>handleFileSelect(e)}/>
                        </div>
                        <div className='col-lg-6 col-sm-12 mb-3'>
                            <select class="form-select" aria-label="Default select example" name='category' onChange={handleChange} >
                                <option selected>Select Category</option>
                                <option value={1}>Veg</option>
                                <option value={2}>Non-Veg</option>
                                <option value={3}>ALL</option>
                            </select>
                        </div>

                        <div className='col-lg-6 col-sm-12 mb-3'>
                            <div class="form-floating mb-3">
                                <input type="text" 
                                class="form-control" id="first_name"
                                        name="typeOfFood"
                                        value={formData.typeOfFood}
                                        onChange={handleChange} />
                                <label for="first_name">Type of Food</label>
                            </div>
                        </div>
                    </div>
                    
                    <div className="row">
                        
                        <div className=' col-lg-6 col-sm-12'>
                        <div class="form-floating mb-3">
                            <input type="text" 
                            class="form-control" id="first_name"
                                    name="first_name"
                                    value={formData.first_name}
                                    onChange={handleChange} />
                            <label for="first_name">First Name</label>
                        </div>
                        </div>
                        
                        <div className=' col-lg-6 col-sm-12'>
                        <div class="form-floating">
                            <input type="text" 
                            class="form-control" id="last_name"
                                    name="last_name"
                                    value={formData.last_name}
                                    onChange={handleChange}  />
                            <label for="last_name">Last Name</label>
                        </div>
                        </div>
                    </div>

                    <div className="row">
                    <div className=' col-lg-6 col-sm-12'>
                        <div class="form-floating mb-3">
                            <input type="number" 
                            class="form-control" id="mobile_number"
                                    name="mobile_number"
                                    value={formData.mobile_number}
                                    onChange={handleChange}  />
                            <label for="mobile_number">Phone Number</label>
                        </div>
                    </div>

                    <div className=' col-lg-6 col-sm-12'>
                        <div class="form-floating">
                            <input type="email" class="form-control" id="email"
                                    name="email"
                                    value={formData.email}
                                    onChange={handleChange} />
                            <label for="email">Email Id</label>
                        </div>
                        </div>
                    </div>


                    <div className="row">
                        <b>Address-1:</b>

                        <div className=' col-lg-6 col-sm-12'>
                        <div class="form-floating mb-3 mt-2">
                            <input type="text" class="form-control" id="house_no"
                                    name="house_no"
                                    value={formData.house_no}
                                    onChange={handleChange} />
                            <label for="house_no">house_no.</label>
                        </div>
                        </div>
                        <div className=' col-lg-6 col-sm-12'>
                        <div class="form-floating mt-2">
                            <input type="text" class="form-control" id="area"
                                    name="area"
                                    value={formData.area}
                                    onChange={handleChange} />
                            <label for="area">Area</label>
                        </div>
                        </div>

                    </div>
                    <div className="row">
                    <div className=' col-lg-6 col-sm-12'>
                        <div class="form-floating mb-3">
                            <input type="text" class="form-control" id="landmark1"
                                    name="landmark1"
                                    value={formData.landmark1}
                                    onChange={handleChange} />
                            <label for="landmark1">Landmark</label>
                        </div>
                        </div>

                        <div className=' col-lg-6 col-sm-12'>

                        <div class="form-floating">
                            <input type="text" class="form-control" id="city1"
                                    name="city1"
                                    value={formData.city1}
                                    onChange={handleChange} />
                            <label for="city1">City</label>
                        </div>
                        </div>

                    </div>

                    <div className="row">
                    <div className=' col-lg-6 col-sm-12'>
                        <div class="form-floating mb-3">
                            <input type="text" class="form-control" id="state1"
                                    name="state1"
                                    value={formData.state1}
                                    onChange={handleChange} />
                            <label for="state1">State</label>
                        </div>
                        </div>

                        <div className=' col-lg-6 col-sm-12'>
                        <div class="form-floating">
                            <input type="text" class="form-control" id="country1"
                                    name="country1"
                                    value={formData.country1}
                                    onChange={handleChange} />
                            <label for="country1">Country</label>
                        </div>
                        </div>
                        


                    </div>

                    <div className='row'>
                    <div className=' col-lg-6 col-sm-12'>
                    <div class="form-floating">
                            <input type="text" class="form-control" id="pincode1"
                                    name="pincode1"
                                    value={formData.pincode1}
                                    onChange={handleChange} />
                            <label for="pincode1">PinCode</label>
                        </div>
                    </div>
                    </div>

                    <div className="login_btn mt-3 mb-3">
                        <button className='btn btn-warning' onClick={(e)=>handleSubmit(e)}><b>Register</b></button>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default AddAdmin
