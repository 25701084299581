import { React, useState } from 'react'
import vector from '../Images/login_food.png'
import logo from '../Images/brand_logo.png'
import './login.css'
import axios from 'axios'

import { Toaster, toast } from 'sonner';

const Login = ({handleUserLogin}) => {

    const [formData, setFormData] = useState({
        mobileNumber: '',
        otp: '',
        isOTPMode: false, // Flag to indicate OTP verification mode
    });


    const handleSendOTP = async (e) => {
        e.preventDefault();
        // Check if mobile number is entered
        if (!formData.mobileNumber) {
            toast.error('Please enter a mobile number.');
            return;
        }

        // Send OTP logic
        try {
            setFormData((prevState) => ({
                ...prevState,
                // Switch to OTP verification mode
            }));
            // Call your API endpoint to send OTP
            // Example:
            const phone = formData.mobileNumber;
            console.log(phone)
            const res = await axios.post(`${process.env.REACT_APP_API_URL}/sendOtp`, { phone });

            if (res.data.success) {
                setFormData((prevState) => ({
                    ...prevState,
                    isOTPMode: true, // Switch to OTP verification mode
                }));
                toast.success('OTP sent successfully.');
            } else {
                toast.error('Failed to send OTP. Please try again.');
            }
        } catch (error) {
            console.error('Error sending OTP:', error);
            toast.error('Please Register First!!');
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleLogin = async (e) => {
        e.preventDefault()
        // Validate OTP and login logic
        try {
          // Call your API endpoint to verify OTP and login
          // Example:
          console.log("For Verification:",formData)
    
          const res = await axios.post(`${process.env.REACT_APP_API_URL}/verifyOtp`, {
            mobileNumber: formData.mobileNumber,
            otp: formData.otp,
          });
          if (res.data.success) {
            handleUserLogin(res.data.token, res.data.uid, res.data.email);
            localStorage.setItem('token', res.data.token);
            localStorage.setItem('role',res.data.role)
            toast.success('Logged In Successfully');
            window.open('/','_self')
          } else {
            toast.error('Invalid OTP. Please try again.');
          }
        } catch (error) {
          console.error('Error logging in with OTP:', error);
          toast.error('Error logging in. Please try again later.');
        }
    };

    return (

        <div className="container backkground_login">

            {/* <Toaster/> */}
            <div className="login_container">
                <div className="left_content">
                    <img src={vector} className='login_svg' />
                </div>

                <div className="right_content">
                    <div className="right_head">
                        <h3 className='titles'>Login</h3>

                        <p>Log in to your account to place an order or manage your meal deliveries.</p>

                    </div>
                    {!formData.isOTPMode ? (
                        <div class="form-floating mb-3">
                            <input type="number" class="form-control mb-3" name="mobileNumber"
                                value={formData.mobileNumber}
                                onChange={handleChange} id="floatingInput" placeholder="Enter Registered Mobile Number" />
                            <label for="floatingInput">Mobile Number</label>
                            <button className='btn know_more_btn' onClick={(e) => handleSendOTP(e)}>
                                Get OTP
                            </button>
                        </div>

                    ) : (
                        <div class="form-floating mb-3">
                            <input type="number" class="form-control mb-3"   name="otp"
                            value={formData.otp}
                            onChange={handleChange}
                            required id="floatingOTP" placeholder="" />
                            <label for="floatingOTP">OTP</label>
                            <br />

                            <button className='btn know_more_btn' onClick={(e) => handleLogin(e)}>
                                Submit
                            </button>
                            
                            {/* <p><b>Forgot Password ? </b></p> */}
                        </div>
                    )}



                    <div className="login_btn">
                        <p className='text-center mt-3'>
                            <b>New User? <a href='/register' className='titles'>Register Here.</a></b></p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Login
