import React from 'react';

class MenuCard extends React.Component {
    render() {
        const { data,title,desc,cat, url, knowMoreClickHandeler,desc_title ,button_text,id,toggle,target} = this.props;
        return (
            <div className="card veg_card mb-3" style={{ maxWidth: "540px" }}>
                <div className="row g-0">
                    <div className="col-md-4">
                        <img
                            src={`${url}`}
                            style={{ borderRadius: '50%', height: '60%', marginTop: '5rem', zIndex: '1' }}
                            className="img-fluid rounded-start"
                            alt="..."
                        />
                    </div>
                    <div className="col-md-8">
                        <div className="card-body veg_card_title">
                            <h5 className="card-title ">{title}</h5>
                            <p className='card-title' style={{ fontSize: '20px', fontWeight: '500' }}>{cat}</p>
                            <p className="card-text mt-3">
                                <b className='mx-3'>{desc_title}</b>
                                <ul>
                                    {desc}
                                </ul>
                            </p>
                        </div>
                        <div className='d-flex justify-content-center'>
                            <button onClick={() => { knowMoreClickHandeler(id,cat) }} className='btn mt-2 know_more_btn' data-bs-toggle={toggle} data-bs-target={target} style={{ zIndex: '1' }}>{button_text}</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default MenuCard;
