import { React, useEffect, useState } from 'react'
import vector from '../Images/register_png.png'
import logo from '../Images/brand_logo.png'
import './register.css'
import axios from 'axios'

const Register = () => {


    const [isSecondAddress, setSecondAddress] = useState(0);

    
    const [formData, setFormData] = useState({
        first_name: '',
        last_name: '',
        mobile_number: '',
        email: '',
        house_no: '',
        area: '',
        landmark1: '',
        city1: '',
        state1: '',
        country1: '',
        pincode1:'',
        house_no2: '',
        street2: '',
        landmark2: '',
        city2: '',
        state2: '',
        country2: '',
        pincode2:'',
        isSecondAddress
    });




    const secondaddress = () => {
        setFormData((prevData) => ({
            ...prevData,
            isSecondAddress: 1  
        }));
    };
    

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
    
        try {
            const res = await axios.post(`${process.env.REACT_APP_API_URL}/register`, formData);
    
            if (res.status === 200) {
                console.log(res.data);
                alert('User Has Been Registered')
                window.open('/login','_self')
            }
        } catch (error) {
            console.log("Error:", error);
        }
    
    };
    
    return (



        <div className="container backkground_login">
            <div className="login_container mt-5">
                <div className="left_content">
                    <img src={vector} className='login_svg' />
                </div>

                <div className="right_content">
                    <div className="right_head">
                        <h3 className='titles'>User Registration</h3>

                        <p>Create an account to start ordering delicious meals for delivery.</p>

                    </div>
                    <div className="row">
                        <div className=' col-lg-6 col-sm-12'>
                        <div class="form-floating mb-3">
                            <input type="text" 
                            class="form-control" id="first_name"
                                    name="first_name"
                                    value={formData.first_name}
                                    onChange={handleChange} />
                            <label for="first_name">First Name</label>
                        </div>
                        </div>
                        
                        <div className=' col-lg-6 col-sm-12'>
                        <div class="form-floating">
                            <input type="text" 
                            class="form-control" id="last_name"
                                    name="last_name"
                                    value={formData.last_name}
                                    onChange={handleChange}  />
                            <label for="last_name">Last Name</label>
                        </div>
                        </div>
                    </div>

                    <div className="row">
                    <div className=' col-lg-6 col-sm-12'>
                        <div class="form-floating mb-3">
                            <input type="number" 
                            class="form-control" id="mobile_number"
                                    name="mobile_number"
                                    value={formData.mobile_number}
                                    onChange={handleChange}  />
                            <label for="mobile_number">Phone Number</label>
                        </div>
                    </div>

                    <div className=' col-lg-6 col-sm-12'>
                        <div class="form-floating">
                            <input type="email" class="form-control" id="email"
                                    name="email"
                                    value={formData.email}
                                    onChange={handleChange} />
                            <label for="email">Email Id</label>
                        </div>
                        </div>
                    </div>


                    <div className="row">
                        <b>Address-1:</b>

                        <div className=' col-lg-6 col-sm-12'>
                        <div class="form-floating mb-3 mt-2">
                            <input type="text" class="form-control" id="house_no"
                                    name="house_no"
                                    value={formData.house_no}
                                    onChange={handleChange} />
                            <label for="house_no">Flatno.</label>
                        </div>
                        </div>
                        <div className=' col-lg-6 col-sm-12'>
                        <div class="form-floating mt-2">
                            <input type="text" class="form-control" id="area"
                                    name="area"
                                    value={formData.area}
                                    onChange={handleChange} />
                            <label for="area">Street</label>
                        </div>
                        </div>

                    </div>
                    <div className="row">
                    <div className=' col-lg-6 col-sm-12'>
                        <div class="form-floating mb-3">
                            <input type="text" class="form-control" id="landmark1"
                                    name="landmark1"
                                    value={formData.landmark1}
                                    onChange={handleChange} />
                            <label for="landmark1">Landmark</label>
                        </div>
                        </div>

                        <div className=' col-lg-6 col-sm-12'>

                        <div class="form-floating">
                            <input type="text" class="form-control" id="city1"
                                    name="city1"
                                    value={formData.city1}
                                    onChange={handleChange} />
                            <label for="city1">City</label>
                        </div>
                        </div>

                    </div>

                    <div className="row">
                    <div className=' col-lg-6 col-sm-12'>
                        <div class="form-floating mb-3">
                            <input type="text" class="form-control" id="state1"
                                    name="state1"
                                    value={formData.state1}
                                    onChange={handleChange} />
                            <label for="state1">State</label>
                        </div>
                        </div>

                        <div className=' col-lg-6 col-sm-12'>
                        <div class="form-floating">
                            <input type="text" class="form-control" id="country1"
                                    name="country1"
                                    value={formData.country1}
                                    onChange={handleChange} />
                            <label for="country1">Country</label>
                        </div>
                        </div>
                        


                    </div>

                    <div className='row'>
                    <div className=' col-lg-6 col-sm-12'>
                    <div class="form-floating">
                            <input type="text" class="form-control" id="pincode1"
                                    name="pincode1"
                                    value={formData.pincode1}
                                    onChange={handleChange} />
                            <label for="pincode1">PinCode</label>
                        </div>
                    </div>
                    </div>


                    <div className="row mt-3">
                        <b id="second_address" className='btn know_more_btn' onClick={secondaddress}>Add Another Address</b>
                    </div>

                    {formData.isSecondAddress > 0  ? (
                        <>
                            <div className="row">
                                <b>Address-2:</b>
                            <div className=' col-lg-6 col-sm-12'>
                                <div class="form-floating mb-3 mt-2">
                                    <input type="text" class="form-control" id="house_no2"
                                    name="house_no2"
                                    value={formData.house_no2}
                                    onChange={handleChange} />
                                    <label for="house_no2">Flatno.</label>
                                </div>
                            </div>

                        <div className=' col-lg-6 col-sm-12'>
                                <div class="form-floating mt-2">
                                    <input type="text" class="form-control" id="street2"
                                    name="street2"
                                    value={formData.street2}
                                    onChange={handleChange} />
                                    <label for="street2">Street</label>
                                </div>
                        </div>

                        
                            </div>


                            <div className="row">

                        <div className=' col-lg-6 col-sm-12'>
                                <div class="form-floating mb-3">
                                    <input type="text" class="form-control" id="landmark2"
                                    name="landmark2"
                                    value={formData.landmark2}
                                    onChange={handleChange} />
                                    <label for="landmark2">Landmark</label>
                                </div>
                                </div>

                        <div className=' col-lg-6 col-sm-12'>
                                <div class="form-floating">
                                    <input type="text" class="form-control" id="city2"
                                    name="city2"
                                    value={formData.city2}
                                    onChange={handleChange} />
                                    <label for="city2">City</label>
                                </div>
                            </div>

                            </div>

                            <div className="row">
                        <div className=' col-lg-6 col-sm-12'>

                                <div class="form-floating mb-3">
                                    <input type="text" class="form-control" id="state2"
                                    name="state2"
                                    value={formData.state2}
                                    onChange={handleChange} />
                                    <label for="state2">State</label>
                                </div>
                                </div>
                        <div className=' col-lg-6 col-sm-12'>

                                <div class="form-floating">
                                    <input type="text" class="form-control" id="country2"
                                    name="country2"
                                    value={formData.country2}
                                    onChange={handleChange} />
                                    <label for="country2">Country</label>
                                </div>
                                </div>                            
                    </div>
                        
                    <div className='row'>
                        <div className='col-lg-6 col-sm-12'>
                            <div class="form-floating">
                            <input type="number" class="form-control" id="pincode2"
                                    name="pincode2"
                                    value={formData.pincode2}
                                    onChange={handleChange} />
                            <label for="pincode2">PinCode</label>
                        </div>
                        </div>
                        </div>
                        </>

                    ) : (

                        <></>
                    )}




                    <div className="login_btn mt-5 mb-5">
                        <button className='btn btn-warning' onClick={(e)=>handleSubmit(e)}><b>Register</b></button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Register
