import React, { useState } from "react";
import logo from '../Images/brand_logo.png'


import { NavLink } from "react-router-dom";
import './navbar.css'
const Navbar = () => {
  const [showMediaIcons, setShowMediaIcons] = useState(false);
  console.log(localStorage.getItem('role'))
  return (
    <>
    <div class="container mt-2">
    <nav class="navbar navbar-expand-lg">
      
      <a href="/" class="d-flex align-items-center mb-3 mb-md-0 me-md-auto link-body-emphasis text-decoration-none">
        <img class="bi me-2" width="60" height="70" src={logo}></img>
      </a>

      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="collapse navbar-collapse flex-grow-0" id="navbarSupportedContent">
        <ul class="navbar-nav nav-pills me-auto mb-2 mb-lg-0">
          <li class="nav-item"><a href="/#Menu" class="nav-link text-dark" aria-current="page">Menu</a></li>
          <li class="nav-item"><a href="/#whyus" class="nav-link text-dark">Why Us</a></li>
          <li class="nav-item"><a href="/#reviews" class="nav-link text-dark">Reviews</a></li>
          <li class="nav-item"><a href="/#concat" class="nav-link text-dark">Contact</a></li>
          <li class="nav-item"><a href="/#faq" class="nav-link text-dark">FAQs</a></li>
          {
            localStorage.getItem('token')==undefined | localStorage.getItem('token')==null ?
            <li class="nav-item"><a href="/login" class="nav-link text-dark">Login</a></li>:
            <li class="nav-item"><a href="/dashboard" class="nav-link text-dark">Dashboard</a></li>
          }
          {
            (localStorage.getItem('role')==undefined | localStorage.getItem('token')==null) ?
            <></>:
            (localStorage.getItem('role')==3 | localStorage.getItem('role')=='3') ?
            <></>:
            (localStorage.getItem('role')==1 | localStorage.getItem('role')=='1') ?
            <>
              <li class="nav-item dropdown">
                <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  Master
                </a>
                <ul class="dropdown-menu">
                  <li><a class="dropdown-item" href="/AddAdmins">Add Admin</a></li>
                  <li><a class="dropdown-item" href="/AddMenu">Add Menu</a></li>
                  <li><a class="dropdown-item" href="/AllSubScriptions">All Subscriptions</a></li>
                </ul>
              </li>
            </>:
            (localStorage.getItem('role')==2 | localStorage.getItem('role')=='2') ?
            <>
             <li class="nav-item dropdown">
                <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  Master
                </a>
                <ul class="dropdown-menu">
                  <li><a class="dropdown-item" href="/AddMenu">Add Menu</a></li>
                  <li><a class="dropdown-item" href="/AllSubScriptions">All Subscriptions</a></li>
                </ul>
              </li>
            </>:
            <></>
          }
          {
            localStorage.getItem('token')==undefined | localStorage.getItem('token')==null ?
            <></>:
            <li class="nav-item"><a href="/logout" class="nav-link text-dark">Logout</a></li>
          }
          {/* <li class="nav-item"><a href="#" class="nav-link success active">Explore Menu</a></li> */}
        </ul>
      </div>
    </nav>
  </div>
  <hr className="mt-0"/>

      {/* hero section  */}
      {/* <section className="hero-section">
        <p>Welcome to </p>
        <h1>Thapa Technical</h1>
      </section> */}
    </>
  );
};

export default Navbar;