import React from 'react'
import './footer.css'
import insta from '../Images/insta.svg'
import fb from '../Images/fb.svg'
import twit from '../Images/twitter.svg'
import play from '../Images/play_store.png'

const Footer = () => {
    return (
        <div className='footer'>
            <footer class="py-5">
                <div class="row m-0">
                    <div class="col-md-4 offset-md-1 mb-3">
                        <form>
                            <h3 style={{color:'white'}}><b>Test Of Home</b></h3>
                            <p>
                                <b>At Taste of Home, we take pride in delivering more than just meals. We offer a taste of comfort, a touch of tradition, and a slice of home in every lunch box.</b> </p>
                            <div class="d-flex flex-column flex-sm-row w-100 gap-2">
                                <p><b>Download our android app </b></p>
                                <img  fdprocessedid="r041nd" id="play" src={play}/>
                            </div>
                        </form>
                    </div>

                    <div class="col-6 col-md-2 mb-3">
                        <h5>Our Store</h5>
                        <ul class="nav flex-column" id="footer_sub">
                            <li class="nav-item mb-2"><a href="#" class="nav-link p-0 text-body-secondary">Home</a></li>
                            <li class="nav-item mb-2"><a href="#" class="nav-link p-0 text-body-secondary">Why Us ? </a></li>
                            <li class="nav-item mb-2"><a href="#" class="nav-link p-0 text-body-secondary">Lunch Box Plans</a></li>
                            <li class="nav-item mb-2"><a href="#" class="nav-link p-0 text-body-secondary">Reviews</a></li>
                            <li class="nav-item mb-2"><a href="#" class="nav-link p-0 text-body-secondary">Support</a></li>
                        </ul>
                    </div>

                    <div class="col-6 col-md-2 mb-3">
                        <h5>Quick Links</h5>
                        <ul class="nav flex-column"  id="footer_sub">
                            <li class="nav-item mb-2"><a href="#" class="nav-link p-0 text-body-secondary">Privacy Policy</a></li>
                            <li class="nav-item mb-2"><a href="#" class="nav-link p-0 text-body-secondary">Terms Of Services</a></li>
                            <li class="nav-item mb-2"><a href="#" class="nav-link p-0 text-body-secondary">Credits</a></li>
                            <li class="nav-item mb-2"><a href="#" class="nav-link p-0 text-body-secondary">FAQs</a></li>
                          
                        </ul>
                    </div>

                    <div class="col-6 col-md-2 mb-3">
                        <h5><b>Social Media</b></h5>
                        <ul class="list-unstyled d-flex">
                        <li class="ms-3"><a class="link-body-emphasis" href="#"><img class="bi" width="24" height="24" src={insta}/></a></li>
                        <li class="ms-3"><a class="link-body-emphasis" href="#"><img class="bi" width="24" height="24" src={fb}></img></a></li>
                        <li class="ms-3"><a class="link-body-emphasis" href="#"><img class="bi" width="24" height="24" src={twit}></img></a></li>
                    </ul>
                    </div>

                </div>

                <div class="d-flex flex-column flex-sm-row justify-content-center py-4 my-4 border-top">
                    <p>© 2024 Company, Inc. All rights reserved.</p>
                   
                </div>
            </footer>
        </div>
    )
}

export default Footer
