import axios from "axios";
import React, { Component } from "react";

class SubscribeUs extends Component{
    constructor(props) {
        super(props);
        this.state = {
          data: {},
          loading: true,
          error: null,
          url :process.env.REACT_APP_REACT_VIEW_DOC_API,
          daysCount:5,
          selectedAdd:0,
          isPlaced:0
        };
        // this.knowMoreClickHandeler = this.knowMoreClickHandeler.bind(this);
      }
    
      componentDidMount() {
        this.fetchData();
      }
    
      fetchData = async () => {
        try {
          const response = await axios.get(`${process.env.REACT_APP_API_URL}/getUserInfo`,{
            headers: {
              'Content-Type': 'application/json',
              authorization: localStorage.getItem('token'),
            },
          });
          this.setState({ data: response.data.data, loading: false });
        } catch (error) {
          this.setState({ error: error, loading: false });
        }
      };

      addHandler = async()=>{
        const {daysCount} = this.state;
        if(daysCount<30){
            this.setState({
                daysCount:daysCount+5
            });
        }
      }

      subHandler = async()=>{
        const {daysCount} = this.state;
        if(daysCount>5){
            this.setState({
                daysCount:daysCount-5
            });
        }
      } 

      handleSubmit = async() =>{
        const {data,selectedAdd,isPlaced,daysCount} = this.state;
        const {selectedMenu} = this.props;

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/makeSubscription`, {
                    'menu_id' : selectedMenu.menu_id,
                    'user_id' : data.uid,
                    'amount_per_meal':selectedMenu.amount,
                    'mealcount': daysCount*2,
                    'total_amount' :  parseInt(selectedMenu.amount)*daysCount*2,
                    'selected_address': selectedAdd,
                },{
              headers: {
                'Content-Type': 'application/json',
                authorization: localStorage.getItem('token'),
              },
            });
            if(response.status==200){
                alert("Subscription Details Saved!")
                window.open("/dashboard",'_self');
            }
          } catch (error) {
            this.setState({ error: error, loading: false });
          }
      }

    render(){
        const {data,selectedAdd,isPlaced} = this.state;
        const {selectedMenu} = this.props;
        
        return(
            <>
            <div class="modal fade" id="SubsribeUs" tabindex="-1" aria-labelledby="SubsribeUsLabel" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                    <div class="modal-header">
                        <h1 class="modal-title fs-5 titles" id="SubsribeUsLabel">Subscribe</h1>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={()=>{this.setState({isPlaced:0})}}></button>
                    </div>
                    {
                        isPlaced==0?
                        <>
                        <div class="modal-body">
                        <h1 className="fs-5 titles">Select Address</h1>
                        <hr className="m-0"/>

                        <div className="add_container">
                            <div className="card mt-2 mt-3">
                                <div className="card-body">
                                    <input className="text-center w-100" type="radio" name='add' id='add' value={0} onChange={()=>this.setState({selectedAdd:0})}/>
                                    <div className="mainAdd d-flex">
                                        <span>{data.house_no}</span>
                                        <span>{data.area}</span>
                                        <span>{data.landmark}</span>
                                        <span>{data.city}</span>
                                        <span>{data.state}</span>
                                        <span>{data.pincode}</span>
                                    </div>
                                </div>
                            </div>

                            {
                                data.has_other_address == 1?
                                <>
                                <div className="card mt-2 mt-3">
                                    <div className="card-body">
                                        <input className="text-center w-100" type="radio" name='add' id='add' value={0} onChange={()=>this.setState({selectedAdd:1})}/>
                                        <div className="mainAdd d-flex">
                                            <span>{data.other_house_no}</span>
                                            <span>{data.other_area}</span>
                                            <span>{data.other_landmark}</span>
                                            <span>{data.other_city}</span>
                                            <span>{data.other_state}</span>
                                            <span>{data.other_pincode}</span>
                                        </div>
                                    </div>
                                </div>
                                
                                </>
                                :
                                <>
                                </>
                            }
                        </div>

                        <div className="details_container mt-3 mb-3">
                            <h1 className="fs-5 titles" >Select Subscripetion Details</h1>
                            <hr className="m-0"/>

                            <div className="subCounter d-flex justify-content-around mt-3 mb-3">
                                <h1 onClick={()=>this.subHandler()}>-</h1>
                                <input type="number" className="text-center" style={{width:'8rem', borderRadius:"1rem"}} disabled value={this.state.daysCount}/>
                                <h1 onClick={()=>this.addHandler()}>+</h1>
                            </div>

                            <div className="mealDetails">
                                <table className="w-100 m-2">
                                    <tr>
                                        <td className='titles'>Amount Per Meal</td>
                                        <td>{selectedMenu.amount}</td>
                                    </tr>
                                    <tr>
                                        <td className='titles'>Total Days</td>
                                        <td>{this.state.daysCount}</td>
                                    </tr>
                                    <tr>
                                        <td className='titles'>Total Meals</td>
                                        <td>{this.state.daysCount*2}</td>
                                    </tr>
                                    <tr>
                                        <td className='titles'>Total Amount</td>
                                        <td>{selectedMenu.amount*this.state.daysCount*2}</td>
                                    </tr>
                                </table>
                            </div>

                            <div className="paydetails mt-3 mb-3">
                                <h1 className="fs-5 titles" >Select Payment Method</h1>
                                <hr className="m-0"/>
                                <div className="cod d-flex justify-content-between w-50 m-3">
                                    <input type="radio" name="pay" id="pay" value="COD"/>
                                    <label htmlFor="pay"> Cash On Delivery</label>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn know_more_btn" onClick={()=>{this.setState({isPlaced:1})}}>Place Subscription Order</button>
                    </div>
                        </>
                        :
                        <>
                            <div class="modal-body">
                                        <h1 className="fs-5 titles">Selected Address</h1>
                                        <hr className="m-0"/>

                                        
                                        <div className="add_container">
                                            {
                                                selectedAdd==0?
                                                <div className="card mt-2 mt-3">
                                                    <div className="card-body">
                                                        <div className="mainAdd d-flex">
                                                            <span>{data.house_no}</span>
                                                            <span>{data.area}</span>
                                                            <span>{data.landmark}</span>
                                                            <span>{data.city}</span>
                                                            <span>{data.state}</span>
                                                            <span>{data.pincode}</span>
                                                        </div>
                                                    </div>
                                                </div> :
                                                <div className="card mt-2 mt-3">
                                                    <div className="card-body">
                                                        <div className="mainAdd d-flex">
                                                            <span>{data.other_house_no}</span>
                                                            <span>{data.other_area}</span>
                                                            <span>{data.other_landmark}</span>
                                                            <span>{data.other_city}</span>
                                                            <span>{data.other_state}</span>
                                                            <span>{data.other_pincode}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                            
                                        </div>

                                        <div className="details_container mt-3 mb-3">
                                            <h1 className="fs-5 titles" >Selected Subscripetion Details</h1>
                                            <hr className="m-0"/>

                                            <div className="mealDetails">
                                                <table className="w-100 m-2">
                                                    <tr>
                                                        <td className='titles'>Amount Per Meal</td>
                                                        <td>{selectedMenu.amount}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className='titles'>Total Days</td>
                                                        <td>{this.state.daysCount}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className='titles'>Total Meals</td>
                                                        <td>{this.state.daysCount*2}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className='titles'>Total Amount</td>
                                                        <td>{selectedMenu.amount*this.state.daysCount*2}</td>
                                                    </tr>
                                                </table>
                                            </div>

                                            <div className="paydetails mt-3 mb-3">
                                                <h1 className="fs-5 titles" >Selected Payment Method</h1>
                                                <hr className="m-0"/>
                                                <div className="cod d-flex justify-content-between w-50 m-3">
                                                    <label htmlFor="pay"> Cash On Delivery</label>
                                                </div>
                                                
                                            </div>
                                        </div>
                                    </div>
                                    <div class="modal-footer">
                                        <button type="button" class="btn know_more_btn" onClick={()=>{this.handleSubmit()}}>Submit</button>
                                    </div>
                        </>
                    }
                    

                    </div>
                </div>
            </div>
            </>
        )
    }
}

export default SubscribeUs;