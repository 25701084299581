import { React, useEffect, useState } from 'react'
import vector from '../Images/register_png.png'
import logo from '../Images/brand_logo.png'
import Register from '../Register/Register'
import axios from 'axios'


const Addmenu = () => {

    const [banner,setBannerImg] = useState(null);

    const [formData, setFormData] = useState({
        title: '',
        Discription: '',
        email:'',
        price: '',
        address_type: 0,
        house_no: '',
        street: '',
        landmark: '',
        city: '',
        state: '',
        country: '',
        comp_name: '',
        zip_code: '',
        other_add: '',
        typeOfFood:'',
        category:'',
    });


    const [isSecondAddress, setSecondAddress] = useState(false);
    const [selectaddress, setSelectAddress] = useState(0);

    const secondaddress = () => {
        setSecondAddress(true);
    }

    const handleaddresstype = (address_type) => {
        if (address_type == 1) {
            setSelectAddress(1)
        }
        else if (address_type == 2) {
            setSelectAddress(2)
        }
        else if (address_type == 3) {
            setSelectAddress(3)
        }
        else {
            console.log('None')
        }
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value
        }));
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            formData['banner'] = banner;
            const res = await axios.post(`${process.env.REACT_APP_API_URL}/addMenu`,{
                'title': formData.title,
                'category': formData.category,
                'description': formData.discription,
                'amount': formData.price,
                'banner_img': banner
            },{
                headers:{
                    'Authorization': localStorage.getItem('token')
                }
            });
    
            if (res.status === 200) {
                console.log(res.data);
                alert('Menu Has Been Registered')
                window.open('/dashboard','_self')
            }
            console.log(res.error);
        } catch (error) {
            console.log("Error:", error);
        }
    };

    const handleFileSelect=async(e)=>{
        const { name, files } = e.target;
    
        const formData = new FormData();
        formData.append("file", files[0]); 
    
        // Make an AJAX request to upload the file
        axios.post(`${process.env.REACT_APP_API_URL}/uploadFile`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': localStorage.getItem('token')
            }
        })
        .then(response => {
            console.log(response.data.filename)
            setBannerImg(response.data.filename);
            alert('Document Uploaded');
        })
        .catch(error => {
            console.error(`Error uploading file:`, error);
        });
    }

    return (



        <div className="container">

            <div className="login_container mt-5">
                <div className="left_content">
                    <img src={vector} className='login_svg' />
                </div>

                <div className="right_content">
                    <div className="right_head">
                        <h3 className='titles'>Add Menu</h3>

                        <p>Add Menu by filling up this form with their information.</p>

                    </div>

                    <div className='row'>
                        <div className='col-lg-12 mb-3'>
                            <label className='know_more_btn w-100 text-center p-2 custom-file-upload' for="file-upload">
                                    Upload Banner Image
                                </label>
                            <input className='banner_img' name='banner_img' id="file-upload" type="file" onChange={(e)=>handleFileSelect(e)}/>
                        </div>
                        <div className='col-lg-12 col-sm-12 mb-3'>
                            <select class="form-select" aria-label="Default select example" name='category' onChange={handleChange} >
                                <option selected>Select Category</option>
                                <option value="Veg">Veg</option>
                                <option value="Non-Veg">Non-Veg</option>
                            </select>
                        </div>

                    </div>
                    
                    <div className="row">
                        
                        <div className=' col-lg-6 col-sm-12'>
                        <div class="form-floating mb-3">
                            <input type="text" 
                            class="form-control" id="title"
                                    name="title"
                                    value={formData.title}
                                    onChange={handleChange} />
                            <label for="title">Title</label>
                        </div>
                        </div>
                        
                        <div className=' col-lg-6 col-sm-12'>
                        <div class="form-floating">
                            <textarea type="text" 
                            class="form-control" id="discription"
                                    name="discription"
                                    value={formData.Discription}
                                    onChange={handleChange}  />
                            <label for="discription">Last Name</label>
                        </div>
                        </div>
                    </div>

                    <div className="row">
                    <div className=' col-lg-112 col-sm-12'>
                        <div class="form-floating mb-3">
                            <input type="number" 
                            class="form-control" id="price"
                                    name="price"
                                    value={formData.price}
                                    onChange={handleChange}  />
                            <label for="price">Phone Number</label>
                        </div>
                    </div>
                    </div>

                    <div className="login_btn mt-3 mb-3">
                        <button className='btn btn-warning' onClick={(e)=>handleSubmit(e)}><b>Add Menu</b></button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Addmenu
