import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './review.css'

import AOS from 'aos'
import 'aos/dist/aos.css'
import { useEffect } from "react";


function Review() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: window.innerWidth>992 ? 3 : 1,
    slidesToScroll: 1,
    arrows: false
  };

  useEffect(() => {
    AOS.init({ duration: 1000 })
  }, [])
  const data = [
    {
      name: `Mr.Swapnil Desai`,
      img: `/students/John_Morgan.jpg`,
      review: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.`
    },
    {
      name: `Mr.Anubhav Shukla`,
      img: `/students/Ellie_Anderson.jpg`,
      review: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.`
    },
    {
      name: `Mr.Suraj Mane`,
      img: `/students/Nia_Adebayo.jpg`,
      review: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.`
    },
    {
      name: `Mr.Rushil Sawant`,
      img: `/students/Rigo_Louie.jpg`,
      review: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.`
    },
    {
      name: `Mia Williams`,
      img: `/students/Mia_Williams.jpg`,
      review: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.`
    },

  ];

  return (
    <>
      <div className="why_us_head mt-5">
        <h2>Reviews</h2>
        <div className="why_us_p">
          <p>Read what our customers
            <span style={{
              color: 'orange'
            }}><b>Customers</b></span> have to say about their Taste of Home experience.</p>
        </div>
      </div>
      <div className="card-container" style={{border:'none'}}>
        <div className="card"> 
          <div className='new' data-aos="fade-up">
            <div className="mt-20">
              <Slider {...settings}>
                {data.map((d) => (
                  <div key={d.name} className="review_card bg-white h-[500px] text-black rounded-xl">
                    {/* <div className='h-56 bg-indigo-500 flex justify-center items-center rounded-t-xl'>
              <img src={d.img} alt="" className="h-44 w-44 rounded-full"/>
            </div> */}

                    <div className="flex flex-col items-center justify-center gap-4 p-4">
                      <h6 className="text-xl font-semibold" style={{ color: 'orange' }}>Delicious and Filling!!</h6>
                      <p className="text-start">"I ordered the vegetarian lunch box, and it was amazing! The food was fresh, flavorful, and really filled me up. Definitely ordering again!"</p>
                      <p className="text-start"><b>John Doe</b><span className="mx-2" style={{ fontSize: '12px' }}>Senior Developer</span></p>
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
            <br />
            <br />
          </div>
        </div>
      </div>
    </>
  );
}



export default Review;