import react, { Component } from 'react';
import veg from '../Images/veg-1.jpeg'
import axios from 'axios';
import MenuCard from '../CategoryWiseMenu/subComponents/menuCard';

class SelectHome extends Component{
    constructor(props) {
        super(props);
        this.state = {
          data: null,
          loading: true,
          error: null,
          url :process.env.REACT_APP_REACT_VIEW_DOC_API
        };
        this.knowMoreClickHandeler = this.knowMoreClickHandeler.bind(this);
      }
    
      componentDidMount() {
        
        this.fetchData();
      }
    
      fetchData = async () => {
        try {
          const response = await axios.get(`${process.env.REACT_APP_API_URL}/getAllAdmins`);
          this.setState({ data: response.data.data, loading: false });
        } catch (error) {
          this.setState({ error: error, loading: false });
        }
      };

      knowMoreClickHandeler = async(id) =>{
        if(localStorage.getItem('token')==undefined || localStorage.getItem('token')==null)
        {
            alert("You Have to login!!")
        }
        else{
            window.open(`/subCategories?id=${id}`,'_self')
        }
      }

    render(){
        const {data,url} = this.state;
        const homeItems = []
        if(data!=null){
            data.forEach((e,i) => {
                homeItems.push(
                    <MenuCard
                        data = {e}
                        url = {`${url}/${e.banner}`}
                        title = {e.first_name + ' ' + e.last_name}
                        knowMoreClickHandeler = {this.knowMoreClickHandeler}
                        button_text="Know More"
                        desc_title = "From Were We Serve"
                        desc={
                                <>
                                <li> {e.landmark}</li>
                                <li> {e.area}</li>
                                <li> {e.city}</li>
                                <li> {e.state}</li>
                                <li> {e.pincode}</li></>
                        }
                        cat = {e.type_of_food}
                        id = {e.uid}
                    />
                )     
            });
        }
        
        return(
            <>
              <div className="lunch_box_plans " id='Menu'>
                    <div className="why_us_head">
                        <h2>Select Your Home</h2>
                        <div className="why_us_p">
                            <p> Explore our delicious range of lunch boxes, carefully crafted to satisfy your cravings and bring you the
                                <br />
                                <span style={{
                                    color: 'orange'
                                }}><b> comfort of home</b></span>
                            </p>
                        </div>
                    </div>

                </div>

                <div className="veg-1 d-flex flex-wrap">

                    {homeItems}

                </div>
            </>
        )
    }
}

export default SelectHome;