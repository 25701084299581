import { React, useState, useEffect } from 'react'
import './userdash.css'
import UserNavBar from '../NavBar/UserNavBar'
import { Component } from 'react'
import Navbar from '../NavBar/NavBar'
import axios from 'axios'

class UserDashBoard extends Component {

    constructor(props) {
        super(props);
        this.state = {
          subScription:null,
          data: null,
          loading: true,
          error: null,
          url :process.env.REACT_APP_REACT_VIEW_DOC_API
        };
        // this.knowMoreClickHandeler = this.knowMoreClickHandeler.bind(this);
      }
    
      componentDidMount() {
        this.fetchData();
      }
    
      fetchData = async () => {
        try {
          const response = await axios.get(`${process.env.REACT_APP_API_URL}/getUserInfo`,{
            headers: {
              'Content-Type': 'application/json',
              authorization: localStorage.getItem('token'),
            },
          });
          this.setState({ data: response.data.data, loading: false });
        } catch (error) {
          this.setState({ error: error, loading: false });
        }

        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/mySubscriptions`,{
              headers: {
                'Content-Type': 'application/json',
                authorization: localStorage.getItem('token'),
              },
            });
            this.setState({ subScription: response.data.data, loading: false });
          } catch (error) {
            this.setState({ error: error, loading: false });
          }
      };

      handlePauseResume = async(subscription_id,status) =>{
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/pauseResumeStatus`,{
                sub_id:subscription_id,
                status:status
            },{
              headers: {
                'Content-Type': 'application/json',
                authorization: localStorage.getItem('token'),
              },
            });
            if(response.status==200){
                alert("Status Updated!");
                window.location.reload();
            }
          } catch (error) {
            this.setState({ error: error, loading: false });
          }
      }

    render(){
        const {data,subScription} = this.state;
        const subItems = []

        if(subScription!=null){
            subScription.forEach(e => {
                let a = []
        
                String(e.discription).split('\n').forEach(i=>{
                  a.push(
                    <li>{i}</li>
                  )
                })
                
                subItems.push(
                    <div className="sub_cards">
                        <div className="card_1">
                            <div className='card-img-top sub_img'>
                                <img className='hmm' height={150} src={`${process.env.REACT_APP_REACT_VIEW_DOC_API}/${e.banner_img}`}></img>
                            </div>
                            <div className="card_head text-center">
                            <span className='titles'>{e.title}</span>
                            </div>
                            
                            <div className='m-2 mt-4'>
                                <h4 className='titles'>Description</h4>
                                <hr className='mt-2'/>
                                {a}
                            </div>

                            <div className='m-2 mt-4'>
                                <h4  className='titles'>Subscription Details</h4>
                                <hr className='mt-2'/>
                                <table className='w-100'>
                                    <tr>
                                        <td className='titles'>
                                            Active Status
                                        </td>
                                        <td>
                                            {e.active_status==0 ? "Active" : "Paused"}
                                        </td>
                                    </tr>

                                    <tr>
                                        <td className='titles'>
                                            Amount Per Meal
                                        </td>
                                        <td>
                                            {e.amount_per_meal}
                                        </td>
                                    </tr>

                                    <tr>
                                        <td className='titles'>
                                            Total Meals Selected
                                        </td>
                                        <td>
                                            {e.mealcount}
                                        </td>
                                    </tr>

                                    <tr>
                                        <td className='titles'>
                                            Total Amount
                                        </td>
                                        <td>
                                            {e.total_amount}
                                        </td>
                                    </tr>

                                    <tr>
                                        <td className='titles'>
                                            Remaining Meals
                                        </td>
                                        <td>
                                            {e.pending_meal_count}
                                        </td>
                                    </tr>

                                    <tr>
                                        <td className='titles'>
                                            Date Of Subscription
                                        </td>
                                        <td>
                                            {e.new_date}
                                        </td>
                                    </tr>

                                    <tr>
                                        <td className='titles'>
                                            Mode Of Payment
                                        </td>
                                        <td>
                                            COD
                                        </td>
                                    </tr>
                                </table>
                            </div>


                            <div className='btns mt-3'>
                                <button className='btn know_more_btn  w-100' onClick={()=>{
                                        e.active_status==0? this.handlePauseResume(e.subscription_id,1) : this.handlePauseResume(e.subscription_id,0)
                                    }}>
                                    {e.active_status==0? "Pause" : "Resume"}
                                </button>
                            </div>
                            
                        </div>
                    </div>
                )
            });
        }
        
    return (
<>
{
    data!=null ?
    <div className="container">
            <div className="profile">

                <div className="profile_info">
                    <h2>Hello, {data.first_name} {data.last_name}</h2>
                    <p>Welcome to Taste of Home</p>
                </div>
            </div>

            <div className="account_info">
                <div className="account_info_head">
                    <h5>Account Information</h5>
                </div>

                <div className="account_sub_info">
                    <div className="first">
                        <p><span>First Name:</span> </p>
                        <p>{data.first_name}</p>
                    </div>
                    <div className="first">
                        <p><span>Last Name:</span> </p>
                        <p>{data.last_name}</p>
                    </div>
                    <div className="first">
                        <p><span>Email ID: </span></p>
                        <p>{data.email}</p>
                    </div>
                    <div className="first">
                        <p><span>Phone Numb</span>er: </p>
                        <p>{data.mobile_number}</p>
                    </div>
                </div>

                <hr />
            </div>

            <div className="address_cards">
                <div className="address_head">
                    <h5>Address</h5>
                </div>
                <div className="address_options">
                <div className="address_1">
                            {data.house_no}<br/>
                            {data.area}<br/>
                            {data.landMark}<br/>
                            {data.city}<br/>
                            {data.state}
                            {data.pincode}
                </div>

                {
                    data.has_other_address == 1?
                    <div className="address_1">
                        
                                {data.other_house_no}<br/>
                                {data.other_area}<br/>
                                {data.other_landmark}<br/>
                                {data.other_city}<br/>
                                {data.other_state}<br/>
                                {data.other_pincode}
                    </div>
                    :
                    <></>
                }
                
                </div>
                <hr/>
            </div>

            <div className="subscrips">
                <div className="account_info_head">
                    <h5>Your Subscriptions</h5>
                </div>
                <div className="d-flex flex-wrap g-3 justify-content-between w-100 mb-3 subcard_container">
                {
                    subScription==null ? <p className='text-center'>You Do Not Have Any Subscriptions!</p>:<></>
                }
                {subItems}  
                </div>              
            </div>
        </div>
        :
        <p>No Data Available</p>
}
        
        </>
    )
}
}

export default UserDashBoard
