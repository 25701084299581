import React from 'react'
import { FaSearch } from 'react-icons/fa';
import './admindash.css'
import Navbar from '../NavBar/NavBar';
const AdminDashBoard = () => {



    return (

        <>
       
        <div className="container">

            <div className="profile">

                <div className="profile_info">
                    <h2>Hello, Suraj Mane</h2>
                    <p>Welcome to Admin DashBoard</p>
                </div>
            </div>

            <div className="admin_search_bar">

                <div class="input-group mb-3 w-50">
                    <input type="text" class="form-control" placeholder="Recipient's username" aria-label="Recipient's username" aria-describedby="basic-addon2" />
                    <span class="input-group-text" id="basic-addon2"><FaSearch /></span>
                </div>


            </div>



            {/* Main Cards */}


            <div className="main_admin_cards">
            <div className="admin_delivery_cards">

                <div className="pending_card">
                <div className="delievery_cards_head">
                <div className="pending">Pending Delivery</div>

            </div>
                    <div className="pending_cards_container">

                        <div className="pending_card_content">
                            <div className='pend_card_1'>
                                <div className="order_id_info">
                                    <div className="order_id">
                                        <p>Order Id
                                            <br />
                                            <span id='real_id'>#2321</span></p>
                                        <p></p>
                                    </div>
                                    <div className="order_id">
                                        <p>Date:
                                            <br />
                                            <span>23/10/2024</span></p>
                                        <p></p>
                                    </div>
                                </div>

                                <div className="vertical-line"></div>

                                <div className="pend_main_content">
                                    <h5>Veg Standard Box</h5>
                                    <div className="card_address mt-2">
                                        <p><b>Address:</b>
                                            <span id='add_span'>Lorem ipsum dolor sit
                                            <br/>
                                             amet consectetur
                                             <br/>
                                              adipisicing elit. Iure, eveniet.</span>
                                        </p>
                                    </div>

                                    <div className="pend_card_profiles">
                                        <div className="pend_user">
                                            <div className="pend_profile d-flex gap-1">

                                                <div className="user_card_img">
                                                    <img src='https://images.thequint.com/thequint%2F2023-01%2Fcbdd4cf6-101f-4eeb-b415-1999882e25fb%2F1612298825368240128_2.jpg' alt='profile_photo' />
                                                </div>

                                                <div className="user_card">
                                                    <p>User
                                                        <br />
                                                        Suraj Mane
                                                        <br />
                                                        7894561230</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="pend_user">
                                            <div className="pend_profile d-flex gap-1">

                                                <div className="user_card_img">
                                                    <img src='https://images.thequint.com/thequint%2F2023-01%2Fcbdd4cf6-101f-4eeb-b415-1999882e25fb%2F1612298825368240128_2.jpg' alt='profile_photo' />
                                                </div>

                                                <div className="user_card">
                                                    <p>User
                                                        <br />
                                                        Suraj Mane
                                                        <br />
                                                        7894561230</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='pend_card_1'>
                                <div className="order_id_info">
                                    <div className="order_id">
                                        <p>Order Id
                                            <br />
                                            <span id='real_id'>#2321</span></p>
                                        <p></p>
                                    </div>
                                    <div className="order_id">
                                        <p>Date:
                                            <br />
                                            <span>23/10/2024</span></p>
                                        <p></p>
                                    </div>
                                </div>

                                <div className="vertical-line"></div>

                                <div className="pend_main_content">
                                    <h5>Veg Standard Box</h5>
                                    <div className="card_address mt-2">
                                        <p><b>Address:</b>
                                            <span id='add_span'>Lorem ipsum dolor sit
                                            <br/>
                                             amet consectetur
                                             <br/>
                                              adipisicing elit. Iure, eveniet.</span>
                                        </p>
                                    </div>

                                    <div className="pend_card_profiles">
                                        <div className="pend_user">
                                            <div className="pend_profile d-flex gap-1">

                                                <div className="user_card_img">
                                                    <img src='https://images.thequint.com/thequint%2F2023-01%2Fcbdd4cf6-101f-4eeb-b415-1999882e25fb%2F1612298825368240128_2.jpg' alt='profile_photo' />
                                                </div>

                                                <div className="user_card">
                                                    <p>User
                                                        <br />
                                                        Suraj Mane
                                                        <br />
                                                        7894561230</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="pend_user">
                                            <div className="pend_profile d-flex gap-1">

                                                <div className="user_card_img">
                                                    <img src='https://images.thequint.com/thequint%2F2023-01%2Fcbdd4cf6-101f-4eeb-b415-1999882e25fb%2F1612298825368240128_2.jpg' alt='profile_photo' />
                                                </div>

                                                <div className="user_card">
                                                    <p>User
                                                        <br />
                                                        Suraj Mane
                                                        <br />
                                                        7894561230</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='pend_card_1'>
                                <div className="order_id_info">
                                    <div className="order_id">
                                        <p>Order Id
                                            <br />
                                            <span id='real_id'>#2321</span></p>
                                        <p></p>
                                    </div>
                                    <div className="order_id">
                                        <p>Date:
                                            <br />
                                            <span>23/10/2024</span></p>
                                        <p></p>
                                    </div>
                                </div>

                                <div className="vertical-line"></div>

                                <div className="pend_main_content">
                                    <h5>Veg Standard Box</h5>
                                    <div className="card_address mt-2">
                                        <p><b>Address:</b>
                                            <span id='add_span'>Lorem ipsum dolor sit
                                            <br/>
                                             amet consectetur
                                             <br/>
                                              adipisicing elit. Iure, eveniet.</span>
                                        </p>
                                    </div>

                                    <div className="pend_card_profiles">
                                        <div className="pend_user">
                                            <div className="pend_profile d-flex gap-1">

                                                <div className="user_card_img">
                                                    <img src='https://images.thequint.com/thequint%2F2023-01%2Fcbdd4cf6-101f-4eeb-b415-1999882e25fb%2F1612298825368240128_2.jpg' alt='profile_photo' />
                                                </div>

                                                <div className="user_card">
                                                    <p>User
                                                        <br />
                                                        Suraj Mane
                                                        <br />
                                                        7894561230</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="pend_user">
                                            <div className="pend_profile d-flex gap-1">

                                                <div className="user_card_img">
                                                    <img src='https://images.thequint.com/thequint%2F2023-01%2Fcbdd4cf6-101f-4eeb-b415-1999882e25fb%2F1612298825368240128_2.jpg' alt='profile_photo' />
                                                </div>

                                                <div className="user_card">
                                                    <p>User
                                                        <br />
                                                        Suraj Mane
                                                        <br />
                                                        7894561230</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='pend_card_1'>
                                <div className="order_id_info">
                                    <div className="order_id">
                                        <p>Order Id
                                            <br />
                                            <span id='real_id'>#2321</span></p>
                                        <p></p>
                                    </div>
                                    <div className="order_id">
                                        <p>Date:
                                            <br />
                                            <span>23/10/2024</span></p>
                                        <p></p>
                                    </div>
                                </div>

                                <div className="vertical-line"></div>

                                <div className="pend_main_content">
                                    <h5>Veg Standard Box</h5>
                                    <div className="card_address mt-2">
                                        <p><b>Address:</b>
                                            <span id='add_span'>Lorem ipsum dolor sit
                                            <br/>
                                             amet consectetur
                                             <br/>
                                              adipisicing elit. Iure, eveniet.</span>
                                        </p>
                                    </div>

                                    <div className="pend_card_profiles">
                                        <div className="pend_user">
                                            <div className="pend_profile d-flex gap-1">

                                                <div className="user_card_img">
                                                    <img src='https://images.thequint.com/thequint%2F2023-01%2Fcbdd4cf6-101f-4eeb-b415-1999882e25fb%2F1612298825368240128_2.jpg' alt='profile_photo' />
                                                </div>

                                                <div className="user_card">
                                                    <p>User
                                                        <br />
                                                        Suraj Mane
                                                        <br />
                                                        7894561230</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="pend_user">
                                            <div className="pend_profile d-flex gap-1">

                                                <div className="user_card_img">
                                                    <img src='https://images.thequint.com/thequint%2F2023-01%2Fcbdd4cf6-101f-4eeb-b415-1999882e25fb%2F1612298825368240128_2.jpg' alt='profile_photo' />
                                                </div>

                                                <div className="user_card">
                                                    <p>User
                                                        <br />
                                                        Suraj Mane
                                                        <br />
                                                        7894561230</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>


                <div className="dispatched_card">
                <div className="delievery_cards_head">
                <div className="pending">Dispatched</div>

            </div>
                 <div className="pending_cards_container" style={{backgroundColor:'orange'}}>
                        <div className="pending_card_content">

                        <div className='pend_card_1'>
                                <div className="order_id_info">
                                    <div className="order_id">
                                        <p>Order Id
                                            <br />
                                            <span id='real_id'>#2321</span></p>
                                        <p></p>
                                    </div>
                                    <div className="order_id">
                                        <p>Date:
                                            <br />
                                            <span>23/10/2024</span></p>
                                        <p></p>
                                    </div>
                                </div>

                                <div className="vertical-line"></div>

                                <div className="pend_main_content">
                                    <h5>Veg Standard Box</h5>
                                    <div className="card_address mt-2">
                                        <p><b>Address:</b>
                                            <span id='add_span'>Lorem ipsum dolor sit
                                            <br/>
                                             amet consectetur
                                             <br/>
                                              adipisicing elit. Iure, eveniet.</span>
                                        </p>
                                    </div>

                                    <div className="pend_card_profiles">
                                        <div className="pend_user">
                                            <div className="pend_profile d-flex gap-1">

                                                <div className="user_card_img">
                                                    <img src='https://images.thequint.com/thequint%2F2023-01%2Fcbdd4cf6-101f-4eeb-b415-1999882e25fb%2F1612298825368240128_2.jpg' alt='profile_photo' />
                                                </div>

                                                <div className="user_card">
                                                    <p>User
                                                        <br />
                                                        Suraj Mane
                                                        <br />
                                                        7894561230</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="pend_user">
                                            <div className="pend_profile d-flex gap-1">

                                                <div className="user_card_img">
                                                    <img src='https://images.thequint.com/thequint%2F2023-01%2Fcbdd4cf6-101f-4eeb-b415-1999882e25fb%2F1612298825368240128_2.jpg' alt='profile_photo' />
                                                </div>

                                                <div className="user_card">
                                                    <p>User
                                                        <br />
                                                        Suraj Mane
                                                        <br />
                                                        7894561230</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='pend_card_1'>
                                <div className="order_id_info">
                                    <div className="order_id">
                                        <p>Order Id
                                            <br />
                                            <span id='real_id'>#2321</span></p>
                                        <p></p>
                                    </div>
                                    <div className="order_id">
                                        <p>Date:
                                            <br />
                                            <span>23/10/2024</span></p>
                                        <p></p>
                                    </div>
                                </div>

                                <div className="vertical-line"></div>

                                <div className="pend_main_content">
                                    <h5>Veg Standard Box</h5>
                                    <div className="card_address mt-2">
                                        <p><b>Address:</b>
                                            <span id='add_span'>Lorem ipsum dolor sit
                                            <br/>
                                             amet consectetur
                                             <br/>
                                              adipisicing elit. Iure, eveniet.</span>
                                        </p>
                                    </div>

                                    <div className="pend_card_profiles">
                                        <div className="pend_user">
                                            <div className="pend_profile d-flex gap-1">

                                                <div className="user_card_img">
                                                    <img src='https://images.thequint.com/thequint%2F2023-01%2Fcbdd4cf6-101f-4eeb-b415-1999882e25fb%2F1612298825368240128_2.jpg' alt='profile_photo' />
                                                </div>

                                                <div className="user_card">
                                                    <p>User
                                                        <br />
                                                        Suraj Mane
                                                        <br />
                                                        7894561230</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="pend_user">
                                            <div className="pend_profile d-flex gap-1">

                                                <div className="user_card_img">
                                                    <img src='https://images.thequint.com/thequint%2F2023-01%2Fcbdd4cf6-101f-4eeb-b415-1999882e25fb%2F1612298825368240128_2.jpg' alt='profile_photo' />
                                                </div>

                                                <div className="user_card">
                                                    <p>User
                                                        <br />
                                                        Suraj Mane
                                                        <br />
                                                        7894561230</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='pend_card_1'>
                                <div className="order_id_info">
                                    <div className="order_id">
                                        <p>Order Id
                                            <br />
                                            <span id='real_id'>#2321</span></p>
                                        <p></p>
                                    </div>
                                    <div className="order_id">
                                        <p>Date:
                                            <br />
                                            <span>23/10/2024</span></p>
                                        <p></p>
                                    </div>
                                </div>

                                <div className="vertical-line"></div>

                                <div className="pend_main_content">
                                    <h5>Veg Standard Box</h5>
                                    <div className="card_address mt-2">
                                        <p><b>Address:</b>
                                            <span id='add_span'>Lorem ipsum dolor sit
                                            <br/>
                                             amet consectetur
                                             <br/>
                                              adipisicing elit. Iure, eveniet.</span>
                                        </p>
                                    </div>

                                    <div className="pend_card_profiles">
                                        <div className="pend_user">
                                            <div className="pend_profile d-flex gap-1">

                                                <div className="user_card_img">
                                                    <img src='https://images.thequint.com/thequint%2F2023-01%2Fcbdd4cf6-101f-4eeb-b415-1999882e25fb%2F1612298825368240128_2.jpg' alt='profile_photo' />
                                                </div>

                                                <div className="user_card">
                                                    <p>User
                                                        <br />
                                                        Suraj Mane
                                                        <br />
                                                        7894561230</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="pend_user">
                                            <div className="pend_profile d-flex gap-1">

                                                <div className="user_card_img">
                                                    <img src='https://images.thequint.com/thequint%2F2023-01%2Fcbdd4cf6-101f-4eeb-b415-1999882e25fb%2F1612298825368240128_2.jpg' alt='profile_photo' />
                                                </div>

                                                <div className="user_card">
                                                    <p>User
                                                        <br />
                                                        Suraj Mane
                                                        <br />
                                                        7894561230</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>



                <div className="delievered_card">
                <div className="delievery_cards_head">
                <div className="pending">Delievered</div>

            </div>
                <div className="pending_cards_container" style={{backgroundColor:'cyan'}}>
                        <div className="pending_card_content">
                            <div className='pend_card_1'>
                                <div className="order_id_info">
                                    <div className="order_id">
                                        <p>Order Id
                                            <br />
                                            <span id='real_id'>#2321</span></p>
                                        <p></p>
                                    </div>
                                    <div className="order_id">
                                        <p>Date:
                                            <br />
                                            <span>23/10/2024</span></p>
                                        <p></p>
                                    </div>
                                </div>

                                <div className="vertical-line"></div>

                                <div className="pend_main_content">
                                    <h5>Veg Standard Box</h5>
                                    <div className="card_address mt-2">
                                        <p><b>Address:</b>
                                            <span id='add_span'>Lorem ipsum dolor sit
                                            <br/>
                                             amet consectetur
                                             <br/>
                                              adipisicing elit. Iure, eveniet.</span>
                                        </p>
                                    </div>

                                    <div className="pend_card_profiles">
                                        <div className="pend_user">
                                            <div className="pend_profile d-flex gap-1">

                                                <div className="user_card_img">
                                                    <img src='https://images.thequint.com/thequint%2F2023-01%2Fcbdd4cf6-101f-4eeb-b415-1999882e25fb%2F1612298825368240128_2.jpg' alt='profile_photo' />
                                                </div>

                                                <div className="user_card">
                                                    <p>User
                                                        <br />
                                                        Suraj Mane
                                                        <br />
                                                        7894561230</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="pend_user">
                                            <div className="pend_profile d-flex gap-1">

                                                <div className="user_card_img">
                                                    <img src='https://images.thequint.com/thequint%2F2023-01%2Fcbdd4cf6-101f-4eeb-b415-1999882e25fb%2F1612298825368240128_2.jpg' alt='profile_photo' />
                                                </div>

                                                <div className="user_card">
                                                    <p>User
                                                        <br />
                                                        Suraj Mane
                                                        <br />
                                                        7894561230</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='pend_card_1'>
                                <div className="order_id_info">
                                    <div className="order_id">
                                        <p>Order Id
                                            <br />
                                            <span id='real_id'>#2321</span></p>
                                        <p></p>
                                    </div>
                                    <div className="order_id">
                                        <p>Date:
                                            <br />
                                            <span>23/10/2024</span></p>
                                        <p></p>
                                    </div>
                                </div>

                                <div className="vertical-line"></div>

                                <div className="pend_main_content">
                                    <h5>Veg Standard Box</h5>
                                    <div className="card_address mt-2">
                                        <p><b>Address:</b>
                                            <span id='add_span'>Lorem ipsum dolor sit
                                            <br/>
                                             amet consectetur
                                             <br/>
                                              adipisicing elit. Iure, eveniet.</span>
                                        </p>
                                    </div>

                                    <div className="pend_card_profiles">
                                        <div className="pend_user">
                                            <div className="pend_profile d-flex gap-1">

                                                <div className="user_card_img">
                                                    <img src='https://images.thequint.com/thequint%2F2023-01%2Fcbdd4cf6-101f-4eeb-b415-1999882e25fb%2F1612298825368240128_2.jpg' alt='profile_photo' />
                                                </div>

                                                <div className="user_card">
                                                    <p>User
                                                        <br />
                                                        Suraj Mane
                                                        <br />
                                                        7894561230</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="pend_user">
                                            <div className="pend_profile d-flex gap-1">

                                                <div className="user_card_img">
                                                    <img src='https://images.thequint.com/thequint%2F2023-01%2Fcbdd4cf6-101f-4eeb-b415-1999882e25fb%2F1612298825368240128_2.jpg' alt='profile_photo' />
                                                </div>

                                                <div className="user_card">
                                                    <p>User
                                                        <br />
                                                        Suraj Mane
                                                        <br />
                                                        7894561230</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='pend_card_1'>
                                <div className="order_id_info">
                                    <div className="order_id">
                                        <p>Order Id
                                            <br />
                                            <span id='real_id'>#2321</span></p>
                                        <p></p>
                                    </div>
                                    <div className="order_id">
                                        <p>Date:
                                            <br />
                                            <span>23/10/2024</span></p>
                                        <p></p>
                                    </div>
                                </div>

                                <div className="vertical-line"></div>

                                <div className="pend_main_content">
                                    <h5>Veg Standard Box</h5>
                                    <div className="card_address mt-2">
                                        <p><b>Address:</b>
                                            <span id='add_span'>Lorem ipsum dolor sit
                                            <br/>
                                             amet consectetur
                                             <br/>
                                              adipisicing elit. Iure, eveniet.</span>
                                        </p>
                                    </div>

                                    <div className="pend_card_profiles">
                                        <div className="pend_user">
                                            <div className="pend_profile d-flex gap-1">

                                                <div className="user_card_img">
                                                    <img src='https://images.thequint.com/thequint%2F2023-01%2Fcbdd4cf6-101f-4eeb-b415-1999882e25fb%2F1612298825368240128_2.jpg' alt='profile_photo' />
                                                </div>

                                                <div className="user_card">
                                                    <p>User
                                                        <br />
                                                        Suraj Mane
                                                        <br />
                                                        7894561230</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="pend_user">
                                            <div className="pend_profile d-flex gap-1">

                                                <div className="user_card_img">
                                                    <img src='https://images.thequint.com/thequint%2F2023-01%2Fcbdd4cf6-101f-4eeb-b415-1999882e25fb%2F1612298825368240128_2.jpg' alt='profile_photo' />
                                                </div>

                                                <div className="user_card">
                                                    <p>User
                                                        <br />
                                                        Suraj Mane
                                                        <br />
                                                        7894561230</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='pend_card_1'>
                                <div className="order_id_info">
                                    <div className="order_id">
                                        <p>Order Id
                                            <br />
                                            <span id='real_id'>#2321</span></p>
                                        <p></p>
                                    </div>
                                    <div className="order_id">
                                        <p>Date:
                                            <br />
                                            <span>23/10/2024</span></p>
                                        <p></p>
                                    </div>
                                </div>

                                <div className="vertical-line"></div>

                                <div className="pend_main_content">
                                    <h5>Veg Standard Box</h5>
                                    <div className="card_address mt-2">
                                        <p><b>Address:</b>
                                            <span id='add_span'>Lorem ipsum dolor sit
                                            <br/>
                                             amet consectetur
                                             <br/>
                                              adipisicing elit. Iure, eveniet.</span>
                                        </p>
                                    </div>

                                    <div className="pend_card_profiles">
                                        <div className="pend_user">
                                            <div className="pend_profile d-flex gap-1">

                                                <div className="user_card_img">
                                                    <img src='https://images.thequint.com/thequint%2F2023-01%2Fcbdd4cf6-101f-4eeb-b415-1999882e25fb%2F1612298825368240128_2.jpg' alt='profile_photo' />
                                                </div>

                                                <div className="user_card">
                                                    <p>User
                                                        <br />
                                                        Suraj Mane
                                                        <br />
                                                        7894561230</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="pend_user">
                                            <div className="pend_profile d-flex gap-1">

                                                <div className="user_card_img">
                                                    <img src='https://images.thequint.com/thequint%2F2023-01%2Fcbdd4cf6-101f-4eeb-b415-1999882e25fb%2F1612298825368240128_2.jpg' alt='profile_photo' />
                                                </div>

                                                <div className="user_card">
                                                    <p>User
                                                        <br />
                                                        Suraj Mane
                                                        <br />
                                                        7894561230</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>


                </div>
            </div>
        </div>
        </div>

        </>
    )
}

export default AdminDashBoard
