import React, { useState } from 'react';
import './faq.css'; // Import your CSS file for styling
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FaEnvelope, FaPhone } from 'react-icons/fa';
const FAQ = () => {
    const [activeOption, setActiveOption] = useState('Ordering'); // State to track active option

    const questions = [
        {
            option: 'Ordering',
            question: 'How are Taste of Home! Lunch Box priced?',
            answer:
                'At Taste of Home, we offer three range of options for our lunch boxes: Veg, Non-Veg, and Alternate Veg Non-Veg. Our pricing is designed to be affordable without compromising on quality and quantity, as we understand that these meals are part of your daily routine.',
        },
        {
            option: 'Ordering',
            question: 'How are Taste of Home! Lunch Box priced?',
            answer:
                'At Taste of Home, we offer three range of options for our lunch boxes: Veg, Non-Veg, and Alternate Veg Non-Veg. Our pricing is designed to be affordable without compromising on quality and quantity, as we understand that these meals are part of your daily routine.',
        },
        {
            option: 'Ordering',
            question: 'How are Taste of Home! Lunch Box priced?',
            answer:
                'At Taste of Home, we offer three range of options for our lunch boxes: Veg, Non-Veg, and Alternate Veg Non-Veg. Our pricing is designed to be affordable without compromising on quality and quantity, as we understand that these meals are part of your daily routine.',
        },
        {
            option: 'Ordering',
            question: 'How are Taste of Home! Lunch Box priced?',
            answer:
                'At Taste of Home, we offer three range of options for our lunch boxes: Veg, Non-Veg, and Alternate Veg Non-Veg. Our pricing is designed to be affordable without compromising on quality and quantity, as we understand that these meals are part of your daily routine.',
        },
        {
            option: 'Delivery',
            question: 'What do I do if my tiffin does not get delivered to me?',
            answer:
                'If your tiffin does not get delivered, please contact our customer support immediately. We will investigate the issue and ensure that your meal is delivered to you as soon as possible.',
        },
        {
            option: 'Payment',
            question: 'Can I change my plan later?',
            answer:
                'Yes, you can change your plan later. We offer flexible plans to suit your needs. Please contact our customer support for assistance.',
        },
        {
            option: 'Ordering',
            question: 'What is your cancellation policy?',
            answer:
                'Our cancellation policy allows you to cancel your order up to 24 hours before the delivery time. For more details, please refer to our terms and conditions or contact our support team.',
        },
    ];

    const filteredQuestions = questions.filter((item) => item.option === activeOption);

    const handleOptionClick = (option) => {
        setActiveOption(option);
    };

    return (
        <div className="faq-container mt-5">
            <div className="why_us_head">
                <h2>Frequently Asked Questions</h2>
                <div className="why_us_p">
                    <p>
                        Explore answers to the Frequently asked <span style={{ color: 'orange' }}><b>questions</b></span> about our service and offerings.
                        <br />
                    </p>
                </div>
                <div className="faq_options d-flex gap-5">
                    <div className={`delievery ${activeOption === 'Ordering' ? 'active' : ''}`} onClick={() => handleOptionClick('Ordering')}>
                        <button className={`btn btn-secondary ${activeOption === 'Ordering' ? 'btn-danger' : ''}`}>Ordering</button>
                    </div>
                    <div className={`delievery ${activeOption === 'Delivery' ? 'active' : ''}`} onClick={() => handleOptionClick('Delivery')}>
                        <button className={`btn btn-secondary ${activeOption === 'Delivery' ? 'btn-danger' : ''}`}>Delivery</button>
                    </div>
                    <div className={`delievery ${activeOption === 'Payment' ? 'active' : ''}`} onClick={() => handleOptionClick('Payment')}>
                        <button className={`btn btn-secondary ${activeOption === 'Payment' ? 'btn-danger' : ''}`}>Payment</button>
                    </div>
                </div>
            </div>

            <div className="main_faqs mt-5">
                <div class="accordion" id="accordionExample">
                    {filteredQuestions.map((item, index) => (
                        <div class="accordion-item" key={index}>
                            <h2 class="accordion-header">
                                <button
                                    class={`accordion-button collapsed`}
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target={`#collapse${index}`}
                                    aria-expanded="false"
                                    aria-controls={`collapse${index}`}
                                >
                                    <b>{item.question}</b>
                                </button>
                            </h2>
                            <div
                                id={`collapse${index}`}
                                class={`accordion-collapse collapse`}
                                aria-labelledby={`heading${index}`}
                                data-bs-parent="#accordionExample"
                            >
                                <div class="accordion-body">
                                    <p>{item.answer}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>


            {/* Have More Questions */}
            <div className="more_questions mt-5">
                <div className="why_us_head">
                    <h2>Have More Questions ?</h2>
                    <div className="why_us_p mt-5">
                        <p>
                        Our customer support team is here to assist you with any questions or concerns you may have.
                            <br />
                        </p>

                        <div className="contacts d-flex justify-content-between">
            <div className="email">
            <FaEnvelope size={30} color="dark" />
                <span className='mx-3'>Mail us at <span style={{  color: 'rgb(255, 148, 47)'}}>brandname@gmail.com</span></span>
            </div>
            <div className="email">
          <FaPhone size={24} style={{ transform: 'scaleX(-1)' }} />
                <span className='mx-3'>Call on <span  style={{  color: 'rgb(255, 148, 47)'}}>+91 9898989898</span></span>
            </div>
        </div>
                    </div>

                </div>
            </div>

            <div className="office_timing">
                <div className="why_us_head">
                <div className="why_us_p">
                *(Office Timings: 9:00 a.m to 5:00 p.m)
                </div>
                </div>
            </div>
        </div>
    );
};

export default FAQ;
